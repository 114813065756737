import { Link } from "gatsby";
import React from "react";
import Layout from "../../../other/layout";
import SEO from "../../../other/seo";
import { PREVIEW } from "../../../project-config";
import guarantee from "../../../assets/img/guarantee.gif";
export default function DealerPoliciesPage() {
  return (
    <Layout>
      <SEO title="100% Quality Guarantee" />

      <div className="staticContent">
        <h1>100% Quality Guarantee</h1>
        <div class="flex flex-row">
          <div>
            <p>
              For 90 days from the day we ship your order, you are covered by
              our 100% "Quality Guarantee" which is applicable to all Printed
              Products.&nbsp; We will replace, refund or credit any of our
              manufactured products found to contain printing errors caused by
              us, or to be defective in workmanship or materials. Any printed
              product created using customer supplied print ready files or
              created using the services of our composition design team are also
              guaranteed to match your approved proof in all aspects.
            </p>
          </div>

          <div>
            <img src={guarantee} alt="100% Quality Guarantee Image" />
          </div>
        </div>
      </div>
    </Layout>
  );
}
